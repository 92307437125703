
import React, { useState, useEffect, createContext, useContext } from "react";
import { FaSearch } from 'react-icons/fa';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import axios from "axios";

import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Circle
} from "react-google-maps";
const styles = require('./style.json')

const initializeValue = [];
export const TodoContext = createContext(initializeValue);

class GeoUtil {
  static toRad(value) {
    return value * Math.PI / 180;
  }

  static calculateDistance(lat1, lon1, lat2, lon2) {
    let R = 3959; // Radius of the Earth in miles
    let dLat = GeoUtil.toRad(lat2 - lat1);
    let dLon = GeoUtil.toRad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(GeoUtil.toRad(lat1)) * Math.cos(GeoUtil.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // The distance in miles
  }
}



const MapWrapped = withScriptjs(withGoogleMap((props) => {
  const { isGeolocationAllowed, setIsGeolocationAllowed, pointData, setPointData, center, setCenter, selectedPark, setSelectedPark, setError, error } = useContext(TodoContext);
  const [clicked, setClicked] = useState(false);
  const [grant, setGrant] = useState(false);


  const toggleClick = () => {
    setClicked(!clicked);
  }

  const getLocation = async () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (permissionStatus) {
          setIsGeolocationAllowed(permissionStatus.state === "granted");

          permissionStatus.onchange = function () {
            setIsGeolocationAllowed(this.state === "granted");
          };
        });
      navigator.geolocation.getCurrentPosition((position) => {
        setGrant(grant => {
          if (!grant) {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
          return true;
        })
      });
    } else {
      setError("Location not supported by this browser.")
    }
  };
  useEffect(() => {
    console.log(isGeolocationAllowed);
    if (!isGeolocationAllowed)
      setError("Please allow location access to this website.")
    else
      setError(null);
  }, [isGeolocationAllowed])
  useEffect(() => {
    const intervalId = setInterval(getLocation, 2000); // Get location every 5 seconds


    const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS6vkFSX31BgJIP3EhZRHZyr-zB02eX1j_DriTYnpT1j2YZ61-1KpNP6aeCRIgLj-rIK8j8I_V-wrde/pub?output=csv'; // Replace with your Google Sheets CSV file URL

    axios.get(csvUrl)    // Use Axios to fetch the CSV data
      .then((response) => {
        let parsedCsvData = parseCSV(response.data);
        // Parse the CSV data into an array of objects                
        parsedCsvData = parsedCsvData.map(e => ({ name: e.Name, location: { lat: Number(e.Lat), lng: Number(e.Lng), vlat: Number(e.VLat), vlng: Number(e.VLng), formattedAddress: e.Address } }))
        //Get Current Location
        parsedCsvData.forEach((place, index) => {
          place.key = index;
        });
        // this.props.setSearchResult(parsedCsvData);
        setPointData(parsedCsvData);
      })
      .catch((error) => {
        console.error('Error fetching CSV data:', error);
      });

    function CSVToArray(CSV_string, delimiter) {
      delimiter = (delimiter || ","); // user-supplied delimeter or default comma

      var pattern = new RegExp( // regular expression to parse the CSV values.
        ( // Delimiters:
          "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
          // Standard fields.
          "([^\"\\" + delimiter + "\\r\\n]*))"
        ), "gi"
      );

      var rows = [[]];  // array to hold our data. First row is column headers.
      // array to hold our individual pattern matching groups:
      var matches = false; // false if we don't find any matches
      // Loop until we no longer find a regular expression match
      while (matches = pattern.exec(CSV_string)) {
        var matched_delimiter = matches[1]; // Get the matched delimiter
        // Check if the delimiter has a length (and is not the start of string)
        // and if it matches field delimiter. If not, it is a row delimiter.
        if (matched_delimiter.length && matched_delimiter !== delimiter) {
          // Since this is a new row of data, add an empty row to the array.
          rows.push([]);
        }
        var matched_value;
        // Once we have eliminated the delimiter, check to see
        // what kind of value was captured (quoted or unquoted):
        if (matches[2]) { // found quoted value. unescape any double quotes.
          matched_value = matches[2].replace(
            new RegExp("\"\"", "g"), "\""
          );
        } else { // found a non-quoted value
          matched_value = matches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        rows[rows.length - 1].push(matched_value);
      }
      return rows; // Return the parsed data Array
    }

    function parseCSV(csvText) {
      let data = [];
      let tdata = CSVToArray(csvText);
      for (let i = 1; i < tdata.length; i++) {
        const rowData = tdata[i];          // Use the regular expression to split the row while handling '\r'        
        const rowObject = {};
        rowObject["VLat"] = rowData[0].split(",")[0];
        rowObject["VLng"] = rowData[0].split(",")[1];
        rowObject["Lat"] = rowData[1].split(",")[0];
        rowObject["Lng"] = rowData[1].split(",")[1];
        rowObject["Name"] = rowData[2];
        rowObject["Address"] = rowData[3];
        data.push(rowObject);
      }
      return data;
    }

    return () => clearInterval(intervalId);
  }, []);


  const onMapClick = (e) => {
    setSelectedPark(null);
    setClicked(false);
    //setPointData([...pointData, { name: "New Pos", location: { lat: e.latLng.lat(), lng: e.latLng.lng(), vlat: e.latLng.lat(), vlng: e.latLng.lng(), formattedAddress: "My Location" } }])
  }
  return (
    <>
      <GoogleMap
        defaultCenter={center}
        center={center}
        onClick={onMapClick}
        streetView={true}
        zoom={4}
        defaultOptions={{
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          styles: styles, // change default map styles
          zoomControl: true,
        }}
      >
        <StandaloneSearchBox
          ref={props.onSearchBoxMounted}
          onPlacesChanged={props.onPlacesChanged}
        >
          <div>
            <input
              className={"searchbox-global " + (clicked ? 'clicked' : '')}
              type="text"
              placeholder={clicked ? "Search for a location" : `\u{1F50D}`}
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                height: `32px`,
                marginTop: `27px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
              }}
              onClick={() => { toggleClick() }}
            />
          </div>
        </StandaloneSearchBox>
        {pointData.map(point => {
          let inrange = GeoUtil.calculateDistance(center.lat, center.lng, point.location.lat, point.location.lng) <= 10;
          return (
            <Marker
              key={point.key}
              position={{
                lat: inrange ? point.location.lat : point.location.vlat,
                lng: inrange ? point.location.lng : point.location.vlng
              }}
              onClick={() => {
                setSelectedPark(point);
                if (inrange)
                  setError(null);
                else
                  setError("You must be within 10 miles to reveal exact location");
              }}
              icon={{
                url: inrange ? "/normal.png" : "/vague.png",
                scaledSize: inrange ? new window.google.maps.Size(24, 30) : new window.google.maps.Size(40, 40)
              }}
            />
          )
        }
        )}
        {
          isGeolocationAllowed &&
          <Marker
            key={9080}
            position={center}
            onClick={() => {
              setSelectedPark(center);
              setError(null);
            }}
            icon={{
              url: "/blue.png",
              scaledSize: new window.google.maps.Size(24, 24)
            }}
          />
        }
        
      </GoogleMap>
    </>


  );
}));
const Main = () => {
  const [selectedPark, setSelectedPark] = useState(null);
  const [error, setError] = useState("Location not supported by this browser.");
  const [pointData, setPointData] = useState([]);
  const [center, setCenter] = useState({ lat: 43.6561, lng: -79.3802 });
  const [isGeolocationAllowed, setIsGeolocationAllowed] = useState(null);
  let refs = {}

  const onSearchBoxMounted = ref => {
    refs.searchBox = ref;
  }

  const onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    setCenter(places[0].geometry.location);
  }
  return (
    <TodoContext.Provider value={{ pointData, setPointData, center, setCenter, selectedPark, setSelectedPark, error, setError, isGeolocationAllowed, setIsGeolocationAllowed }}>
      <HashRouter>
        <div style={{ width: "100%", height: "100vh" }}>
          <MapWrapped
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCTHcfW_ZcT4desK9_6kVk0Le6F_Xsmfg8&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onSearchBoxMounted={onSearchBoxMounted}
            onPlacesChanged={onPlacesChanged}
          />
        </div>
        {
          (selectedPark || !isGeolocationAllowed) && <div className="info-window">
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              {error ?
                <>
                  <p>{error}</p>
                </>
                :
                selectedPark && <>
                  <h2>{selectedPark.name}</h2>
                  <p>{selectedPark.location.formattedAddress}</p>
                </>
              }
            </div>
          </div>
        }
      </HashRouter>
    </TodoContext.Provider>
  );
}

export default Main;